import { axiosInstance } from "@service"
import { action } from "mobx"

export class Notifications {
  @action
  sendNotification = async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/notification/createNotification",
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  listUserNotifications = async (userId, page, size) => {
    try {
      const response = await axiosInstance.get(`/notification/${userId}`, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  listLastUserNotifications = async (userId) => {
    try {
      const response = await axiosInstance.get(`/notification/${userId}/last`, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
