import { axiosInstanceSite, axiosInstanceAdmin, axiosInstance } from "@service"
import axios from "axios"
import _get from "lodash/get"
import { action } from "mobx"

export const PostStatus = {
  DELETED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
}

export class Post {
  @action
  findAllAdmin = async (params) => {
    try {
      const res = await axiosInstanceAdmin.get("/post/admin/all", { params })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }
  @action
  findAll = async (params) => {
    try {
      const res = await axiosInstanceAdmin.get("/post", { params })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  findById = async (id) => {
    try {
      const res = await axiosInstanceSite.get(`/post/${id}`)
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  deletePost = async (id) => {
    try {
      await axiosInstanceAdmin.put(`/post/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      })

      return false
    } catch (error) {
      return true
    }
  }
  @action
  editSitePost = async (id, payload) => {
    try {
      const res = await axiosInstanceSite.put(`/post/update/${id}`, payload, {
        headers: { "Content-Type": "application/json" },
      })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  deleteSitePost = async (id) => {
    try {
      await axiosInstanceSite.put(`/post/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      })

      return false
    } catch (error) {
      return true
    }
  }
  @action
  createPost = async (payload) => {
    try {
      const response = await axiosInstanceSite.post("/post/create", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return _get(error, "response.data", {})
    }
  }

  @action
  checkImageUploaded = async (imageUrl) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.S3_IMAGES}/${imageUrl}`
      )
      return response.data
    } catch (error) {
      return _get(error, "response.data", {})
    }
  }

  @action
  validateVimeoLink = async (link) => {
    try {
      const res = await axios.get(`${process.env.VIMEO_BASE_URL}${link}`)
      return !!_get(res, "data.video_id", false)
    } catch (error) {
      return false
    }
  }

  @action
  getCloudinaryWatermarkLink = async (link) => {
    try {
      const response = await axios.get(link)
      return response.data
    } catch (error) {
      return _get(error, "response.data", {})
    }
  }
}
