import { axiosInstanceAdmin, axiosInstanceSite } from "@service"
import _get from "lodash/get"
import { action } from "mobx"

export const MediaType = {
  PHOTO: 1,
  VIDEO: 2,
}

export const Category = {
  NUDE: 1,
  TOPLESS: 2,
  LINGERIE: 3,
}

export const EssayStatus = {
  DELETED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  SCHEDULE: 3,
}

export const EssayActions = {
  ACTIVATE: "activate",
  DEACTIVATE: "deactivate",
  SCHEDULE: "schedule",
  PUBLISH: "publish",
}

export const CategoryByNumber = {
  1: "Nude",
  2: "Topless",
  3: "Lingerie",
}

export class Essay {
  @action
  findAll = async (params) => {
    try {
      const res = await axiosInstanceAdmin.get("/essay/findAll", { params })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  findAllSite = async (params) => {
    try {
      const res = await axiosInstanceSite.get("/essay/findAll", { params })
      return {
        data: _get(res, "data.data", []),
        total: _get(res, "data.total", 0),
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  findById = async (id, isSite) => {
    const axiosInstance = isSite ? axiosInstanceSite : axiosInstanceAdmin

    try {
      const res = await axiosInstance.get(`/essay/${id}`)
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  insert = async (payload) => {
    try {
      const res = await axiosInstanceAdmin.post("/essay", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  update = async (payload, id) => {
    try {
      const res = await axiosInstanceAdmin.put(`/essay/update/${id}`, payload, {
        headers: { "Content-Type": "application/json" },
      })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  remove = async (id) => {
    try {
      const res = await axiosInstanceAdmin.put(`/essay/delete/${id}`)
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  publish = async (payload) => {
    try {
      const res = await axiosInstanceAdmin.put("/essay/publish", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  searchEssay = async (params) => {
    try {
      const response = await axiosInstanceSite.get(
        `/essay/find-by-name?name=${params}`
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getEssayPhotoVideoComments = async (id) => {
    try {
      const response = await axiosInstanceSite.get(`/essay-photo-video/${id}`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postLike = async ({ mediaId }) => {
    try {
      const response = await axiosInstanceSite.post(
        `/essay-photo-video/liked/${mediaId}`
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postDislike = async ({ mediaId }) => {
    try {
      const response = await axiosInstanceSite.delete(
        `/essay-photo-video/remove-liked/${mediaId}`
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postComment = async ({ essayPhotoVideoId, textComment }) => {
    try {
      const response = await axiosInstanceSite.post(
        `/essay-photo-video/comment`,
        {
          essayPhotoVideoId,
          textComment,
        }
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postReplyComment = async ({ essayPhotoVideoCommentId, textComment }) => {
    try {
      const response = await axiosInstanceSite.post(
        `/essay-photo-video/reply`,
        {
          essayPhotoVideoCommentId,
          textComment,
        }
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  deleteComment = async ({ commentId }) => {
    try {
      const response = await axiosInstanceSite.put(
        `/essay-photo-video/remove-comment/${commentId}`
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  deleteReply = async ({ replyId }) => {
    try {
      const response = await axiosInstanceSite.put(
        `/essay-photo-video/remove-comment-reply/${replyId}`
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getMostLiked = async () => {
    try {
      const response = await axiosInstanceSite.get(`/essay/most-liked`)

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  search = async ({ searchParam, category }) => {
    try {
      const categoryParam = category ? `&category=${category}` : ""
      const res = await axiosInstanceSite.get(
        `/search?query=${searchParam}${categoryParam}`
      )
      return {
        data: _get(res, "data.data", []),
        total: _get(res, "data.total", 0),
      }
    } catch (error) {
      console.error(error)
    }
  }
}
