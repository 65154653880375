export const isBrowser = new Function(
  "try {return this===window;}catch(e){ return false;}"
)

export const getWindow = () => {
  if (isBrowser()) {
    return window
  }
  return {
    localStorage: {
      getItem: () => {},
      setItem: () => {},
      removeItem: () => {},
    },
  }
}
