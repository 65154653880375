import { axiosInstance, axiosInstanceSite } from "@service"
import _get from "lodash/get"
import { action } from "mobx"

export const TypeByNumber = {
  1: "Modelo",
  2: "Fotógrafo",
}

export class Profile {
  @action
  getProfileById = async (id) => {
    try {
      const response = await axiosInstanceSite.get(`/affiliated/profile/${id}`)
      return _get(response, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  getAffiliatedByUsername = async (username) => {
    try {
      const response = await axiosInstanceSite.get(
        `/affiliated/username/${username}`
      )
      return _get(response, "data", null)
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  getSubscriberByUsername = async (username) => {
    try {
      const response = await axiosInstanceSite.get(
        `/subscriber/username/${username}`
      )
      return _get(response, "data", null)
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  findAffiliatedByProfileView = async (username) => {
    try {
      const response = await axiosInstance.get(
        `/affiliated/profile/${username}/view`
      )
      return _get(response, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  findSubscriberByProfileView = async (username) => {
    try {
      const response = await axiosInstance.get(
        `/subscriber/profile/${username}/view`
      )
      return _get(response, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  getContactById = async (id) => {
    try {
      const response = await axiosInstanceSite.get(`/affiliated/${id}`)
      return _get(response, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  getDataSubById = async (id) => {
    try {
      const response = await axiosInstanceSite.get(`/subscriber/admin/by/${id}`)
      return _get(response, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  getAllPostProfile = async (affiliatedId, { current, pageSize }) => {
    try {
      const response = await axiosInstanceSite.get(
        `post/affiliated/${affiliatedId}?current=${current}&pageSize=${pageSize}`
      )
      return {
        data: _get(response, "data.data", []),
        total: _get(response, "data.total", 0),
      }
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  getEssayProfile = async (affiliatedId, { current, pageSize }) => {
    try {
      const response = await axiosInstanceSite.get(
        `essay/creator/${affiliatedId}?current=${current}&pageSize=${pageSize}`
      )
      return {
        data: _get(response, "data.data", []),
        total: _get(response, "data.total", 0),
      }
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  postNewFollow = async (affiliatedId) => {
    try {
      const response = await axiosInstanceSite.post(
        `following/follow/${affiliatedId}`
      )
      return response
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  deletedFollowers = async (affiliatedId) => {
    try {
      const response = await axiosInstanceSite.delete(
        `following/unfollow/${affiliatedId}`
      )
      return response
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }
}
