import { axiosInstanceAdmin, axiosInstanceSite } from "@service"
import { action } from "mobx"

export class Banners {
  @action
  getAllGeneralBanners = async (params) => {
    try {
      const { data } = await axiosInstanceAdmin.get(`general-banner/findAll`, {
        params,
      })

      return { ...data, error: false }
    } catch (error) {
      return { error: true }
    }
  }

  @action
  getGeneralBannerByPageName = async (pageName) => {
    try {
      const response = await axiosInstanceSite.get(
        `/general-banner/findByPageName?pageName=${pageName}`,
        { headers: { "Content-Type": "application/json" } }
      )
      return { data: response.data, error: false }
    } catch (error) {
      return { error: true }
    }
  }

  @action
  registerGeneralBanner = async (body) => {
    try {
      await axiosInstanceAdmin.post(
        "/general-banner",
        { ...body },
        { headers: { "Content-Type": "application/json" } }
      )
      return false
    } catch (error) {
      return true
    }
  }

  @action
  updateGeneralBanner = async (id, body) => {
    try {
      await axiosInstanceAdmin.put(
        `/general-banner/${id}`,
        { ...body },
        { headers: { "Content-Type": "application/json" } }
      )
      return false
    } catch (error) {
      return true
    }
  }

  @action
  deleteGeneralBanner = async (id) => {
    try {
      await axiosInstanceAdmin.put(`/general-banner/delete/${id}`, {
        headers: { "Content-Type": "application/json" },
      })
      return false
    } catch (error) {
      return true
    }
  }

  @action
  getAllEssayBannersAdmin = async () => {
    try {
      const { data } = await axiosInstanceAdmin.get(`banner-essay/findAll`, {
        headers: { "Content-Type": "application/json" },
      })

      return {
        data: data.data,
        sucess: true,
        current: 1,
        error: false,
      }
    } catch (error) {
      return { error: true }
    }
  }

  @action
  getAllEssayBannersSite = async () => {
    try {
      const { data } = await axiosInstanceSite.get(`banner-essay/findAll`, {
        headers: { "Content-Type": "application/json" },
      })
      return { ...data, error: false }
    } catch (error) {
      return { error: true }
    }
  }

  @action
  registerEssayBanner = async (body) => {
    try {
      await axiosInstanceAdmin.post(
        "/banner-essay",
        { ...body },
        { headers: { "Content-Type": "application/json" } }
      )
      return false
    } catch (error) {
      return true
    }
  }

  @action
  updateEssayBanner = async (id, body) => {
    try {
      await axiosInstanceAdmin.put(
        `/banner-essay/${id}`,
        { ...body },
        { headers: { "Content-Type": "application/json" } }
      )

      return false
    } catch (error) {
      return true
    }
  }

  @action
  deleteEssayBanner = async (ids) => {
    try {
      await axiosInstanceAdmin.put(
        `/banner-essay/delete/`,
        { ids },
        {
          headers: { "Content-Type": "application/json" },
        }
      )

      return false
    } catch (error) {
      return true
    }
  }

  @action
  reorderEssayBanners = async (ids) => {
    try {
      await axiosInstanceAdmin.put(
        `/banner-essay/reorder`,
        { ids },
        { headers: { "Content-Type": "application/json" } }
      )

      return false
    } catch (error) {
      return true
    }
  }
}
