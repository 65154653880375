import axios from "axios"
import _get from "lodash/get"

import { getWindow } from "../utils/getWindow"

/* Create axios instances */
export const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
})

export const axiosInstanceSite = axios.create({
  timeout: 60000,
  baseURL: process.env.API_URL,
})

export const axiosInstanceAdmin = axios.create({
  timeout: 60000,
  baseURL: process.env.API_URL,
})

export const axiosInstancesLocality = axios.create({
  baseURL: process.env.API_URL_CITIES_STATES,
})

export const axiosInstanceViaCep = axios.create({
  baseURL: process.env.API_URL_VIA_CEP,
})

/* Request interceptors */
axiosInstanceAdmin.interceptors.request.use((config) => {
  const { user } = JSON.parse(getWindow().localStorage.getItem("auth"))
  const token = _get(user, "token", "")

  config.headers.Authorization = `Bearer ${token}`

  return config
}, null)

axiosInstanceSite.interceptors.request.use((config) => {
  const { user } = JSON.parse(getWindow().localStorage.getItem("authSite"))
  const token = _get(user, "token", "")

  config.headers.Authorization = `Bearer ${token}`

  return config
}, null)

/* Response interceptors */
const handleInterceptorsSuccess = (response) => response

const handleInterceptorsErrorAdmin = (error) => {
  const statusCode = error?.response?.status

  if (statusCode === 401 && window.location.pathname !== "/admin") {
    setTimeout(() => {
      getWindow().localStorage.removeItem("auth")
    }, 300)

    window.location.reload(true)
  }
  return Promise.reject(error)
}

const handleInterceptorsErrorSite = (error) => {
  const statusCode = error?.response?.status

  if (statusCode === 401 && window.location.pathname !== "/login") {
    setTimeout(() => {
      getWindow().localStorage.removeItem("authSite")
    }, 300)

    window.location.reload(true)
  }
  return Promise.reject(error)
}

axiosInstanceAdmin.interceptors.response.use(
  handleInterceptorsSuccess,
  handleInterceptorsErrorAdmin
)

axiosInstanceSite.interceptors.response.use(
  handleInterceptorsSuccess,
  handleInterceptorsErrorSite
)
