import { axiosInstance } from "@service"
import { navigate } from "gatsby"
import _get from "lodash/get"
import { action, observable } from "mobx"
import { persist } from "mobx-persist"

import { getWindow } from "../../../utils/getWindow"

export class AuthSite {
  @persist("object")
  @observable
  user = null

  @observable
  collapseMenu = false

  @observable
  loginErrorMessage = ""

  @observable
  forgotPasswordError = false

  @action
  clearLoginErrorMessage = () => {
    this.loginErrorMessage = ""
  }

  @action
  updateDataStorage = (updateData) => {
    this.user.name = updateData?.name
    this.user.nickname = updateData.nickname ? updateData.nickname : ""
    this.user.urlProfile = updateData?.urlProfile
    this.user.urlPhoto = updateData?.urlPhoto
  }

  @action
  controlCollapseMenu = (collapse) => {
    this.collapseMenu = collapse || !this.collapseMenu
  }

  @action
  getAllCountriesAreaCode = async () => {
    try {
      const response = await axiosInstance.get("/countries/all", {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  @action
  login = async (formData) => {
    try {
      const { data } = await axiosInstance.post(
        "/authSite/login",
        { ...formData },
        { headers: { "Content-Type": "application/json" } }
      )
      this.user = data
      this.clearLoginErrorMessage()
    } catch (e) {
      this.loginErrorMessage = _get(e, "response.data.error.message", "")
    }
  }

  @action
  changePasswordProfile = async (formData) => {
    try {
      const response = await axiosInstance.post(
        "/authSite/login",
        { ...formData },
        { headers: { "Content-Type": "application/json" } }
      )
      this.user = response.data
      this.clearLoginErrorMessage()
      return response
    } catch (e) {
      this.loginErrorMessage = _get(e, "response.data.error.message", "")
      return false
    }
  }

  @action
  logout = () => {
    this.user = null
    getWindow().localStorage.clear()
    if (window) {
      window.location.href = "/login"
    } else {
      navigate("/login")
    }
  }

  @action
  myAccount = () => {
    if (!this.user.type) {
      navigate("/conta-assinante")
    }
    if (this.user.type) {
      navigate("/conta-criador")
    }
  }

  @action
  forgotPasswordValidatePhone = async (payload) => {
    try {
      await axiosInstance.post(
        "/authSite/forgot-password/validate-phone",
        payload,
        { headers: { "Content-Type": "application/json" } }
      )
      return true
    } catch (error) {
      return false
    }
  }

  @action
  sendCode = async (payload) => {
    try {
      const res = await axiosInstance.post("/authSite/send-code", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return res
    } catch (error) {
      return _get(error, "response.data.error.message", "")
    }
  }

  @action
  verifyCode = async (payload) => {
    try {
      const res = await axiosInstance.post("/authSite/verify-code", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return res
    } catch (error) {
      return _get(error, "response.data.message", "")
    }
  }

  @action
  changePassword = async (payload) => {
    try {
      const res = await axiosInstance.put(
        `/authSite/change-password`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      )
      return res
    } catch (error) {
      return _get(error, "response.data.message", "")
    }
  }
}
