import { axiosInstanceAdmin, axiosInstanceSite } from "@service"
import _get from "lodash/get"
import { action } from "mobx"

export const AffiliatedType = {
  MODEL: 1,
  PHOTOGRAFER: 2,
}

export const AffiliatedStatus = {
  DELETED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
}

export const AffiliatedActions = {
  ACTIVATE: "activate",
  DEACTIVATE: "deactivate",
}

export class Affiliated {
  @action
  findAll = async (params) => {
    try {
      const res = await axiosInstanceAdmin.get("/affiliated", { params })
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  editProfileCreatorAdmin = async (id, payload) => {
    try {
      const res = await axiosInstanceAdmin.put(
        `/affiliated/update/${id}`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  updatePlayerId = async (id, playerId) => {
    try {
      const res = await axiosInstanceSite.put(
        `/affiliated/update-playerid/${id}/${playerId}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  editProfileCreator = async (id, payload) => {
    try {
      const res = await axiosInstanceSite.put(
        `/affiliated/update/${id}`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }
  @action
  search = async ({ searchParam, category, pageSize, pageNumber }) => {
    try {
      const res = await axiosInstanceSite.get(
        `/search/creators?query=${searchParam}&affiliatedCategory=${category}&pageSize=${pageSize}&pageNumber=${pageNumber}`
      )
      return {
        data: _get(res, "data.data", []),
        total: _get(res, "data.total", 0),
      }
    } catch (error) {
      console.error(error)
      return {
        data: [],
        total: 0,
      }
    }
  }
}
