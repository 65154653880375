import { axiosInstanceSite } from "@service"
import _get from "lodash/get"
import { action } from "mobx"

export class Feed {
  @action
  getAllPosts = async ({ current, pageSize }, filter = "all") => {
    try {
      const response = await axiosInstanceSite.get(
        `/post?current=${current}&pageSize=${pageSize}&filter=${filter}`
      )
      return {
        data: _get(response, "data.data", []),
        total: _get(response, "data.total", 0),
      }
    } catch (error) {
      console.error(error)
    }
  }

  @action
  searchCreatorProfile = async (params) => {
    try {
      const response = await axiosInstanceSite.get(
        `/affiliated/profile?name=${params}`
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  searchAllCreatorProfile = async (filter) => {
    const status = _get(filter, "status", "")
    const orderBy = _get(filter, "orderBy", "")
    try {
      const response = await axiosInstanceSite.get(
        `/affiliated?status=${status}&orderBy=${orderBy}`
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getMostFollowed = async (idProfileLoged) => {
    try {
      const response = await axiosInstanceSite.get(
        `/following/most-followed/${idProfileLoged}`
      )
      return _get(response, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  postNewFollow = async (idProfileLoged) => {
    try {
      const response = await axiosInstanceSite.post(
        `following/follow/${idProfileLoged}`
      )
      return response
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  deletedFollowers = async (idProfileLoged) => {
    try {
      const response = await axiosInstanceSite.delete(
        `following/unfollow/${idProfileLoged}`
      )
      return response
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  postLike = async ({ postId, affiliatedId }) => {
    try {
      const response = await axiosInstanceSite.post(
        `/post-like/like/${postId}`,
        {
          postId,
          affiliatedId,
        }
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postDislike = async ({ postId, affiliatedId }) => {
    try {
      const response = await axiosInstanceSite.delete(
        `/post-like/not-like/${postId}`,
        {
          data: {
            postId,
            affiliatedId,
          },
        }
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getPost = async ({ postId }) => {
    try {
      const response = await axiosInstanceSite.get(`/post/${postId}`)

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postComment = async ({ postId, affiliatedId, textComment }) => {
    try {
      const response = await axiosInstanceSite.post(`/post-comment`, {
        postId,
        affiliatedId,
        textComment,
      })

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  postReplyComment = async ({ commentId, textComment }) => {
    try {
      const response = await axiosInstanceSite.post(`/post-reply-comment`, {
        commentId,
        textComment,
      })

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  deleteComment = async ({ commentId }) => {
    try {
      const response = await axiosInstanceSite.delete(
        `/post-comment/${commentId}`
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }

  @action
  deleteReply = async ({ replyId }) => {
    try {
      const response = await axiosInstanceSite.delete(
        `/post-reply-comment/${replyId}`
      )

      return response
    } catch (error) {
      console.error(error)
    }
  }
}
