import { axiosInstance } from "@service"
import { navigate } from "gatsby"
import _get from "lodash/get"
import { action, observable } from "mobx"
import { persist } from "mobx-persist"

export class Auth {
  @persist("object")
  @observable
  user = null

  @observable
  collapseMenu = false

  @observable
  loginErrorMessage = ""

  @observable
  forgotPasswordError = false

  @action
  clearLoginErrorMessage = () => {
    this.loginErrorMessage = ""
  }

  @action
  controlCollapseMenu = (collapse) => {
    this.collapseMenu = collapse || !this.collapseMenu
  }

  @action
  login = async (formData) => {
    try {
      const { data } = await axiosInstance.post(
        "/auth/login",
        { ...formData },
        { headers: { "Content-Type": "application/json" } }
      )
      this.user = data
      this.clearLoginErrorMessage()
    } catch (e) {
      this.loginErrorMessage = _get(e, "response.data.error.message", "")
    }
  }

  @action
  logout = () => {
    this.user = null
    navigate("/admin")
  }

  @action
  forgotPassword = async (formData) => {
    try {
      await axiosInstance.post(
        "/auth/forgotPassword",
        { ...formData },
        { headers: { "Content-Type": "application/json" } }
      )
      return false
    } catch (error) {
      return true
    }
  }

  @action
  changePassword = async (formData) => {
    const { password, token } = formData
    return await axiosInstance
      .put(
        `/auth/changePassword/${token}`,
        { password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => res)
      .catch((err) => err)
  }
}
