import {
  axiosInstance,
  axiosInstanceAdmin,
  axiosInstanceSite,
  axiosInstancesLocality,
  axiosInstanceViaCep,
  axiosLocal,
} from "@service"
import _get from "lodash/get"
import { action } from "mobx"
import { persist } from "mobx-persist"

export const SubscriberStatus = {
  DELETED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  OPENED: 3,
}

export class RegistrationCreatorAffiliated {
  @persist("object")
  @action
  registerCreator = async (body) => {
    try {
      await axiosInstance.post(
        "/affiliated/create",
        { ...body },
        { headers: { "Content-Type": "application/json" } }
      )
      return
    } catch (error) {
      return error.response.data
    }
  }

  @action
  getAllStates = async () => {
    try {
      const response = await axiosInstancesLocality.get("/estados")
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getAllCities = async (uf) => {
    try {
      const response = await axiosInstancesLocality.get(
        `/estados/${uf}/municipios`
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  getAllCountriesAreaCode = async () => {
    try {
      const response = await axiosInstance.get(`/countries/all`)
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  validate = async (data) => {
    try {
      await axiosInstance.get("/affiliated/validate", {
        headers: { "Content-Type": "application/json" },
        params: { ...data },
      })

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  @action
  findAllAdmin = async (params) => {
    try {
      const response = await axiosInstanceAdmin.get("/subscriber/admin/all", {
        params,
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}

export class RegistrationCreatorSubscriber {
  @persist("object")
  subscriberWirecardResponse = {}

  @action
  listPlans = async () => {
    try {
      const response = await axiosInstance.get("/wirecard/plans")
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  changePlans = async (subscriptionCode, payload) => {
    try {
      const response = await axiosInstanceSite.put(
        `/wirecard/subscription/${subscriptionCode}`,
        payload
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  changePaymentMode = async (subscriptionCode, payload) => {
    try {
      const response = await axiosInstanceSite.put(
        `/wirecard/subscription/${subscriptionCode}/change_payment`,
        payload
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  updateCreditCard = async (customerId, payload) => {
    try {
      const response = await axiosInstanceSite.put(
        `/wirecard/subscriber/${customerId}/credit-card`,
        payload
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  retryPayment = async (invoiceId) => {
    try {
      const response = await axiosInstanceSite.post(
        `/wirecard/invoice/${invoiceId}/retry`
      )
      await new Promise((r) => setTimeout(r, 10000))
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  cancelPlan = async (subscriptionCode) => {
    try {
      const response = await axiosInstanceSite.put(
        `/wirecard/subscription/${subscriptionCode}/cancel`
      )
      await new Promise((r) => setTimeout(r, 10000))
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  listSubscriptionInvoices = async (subscriptionCode) => {
    try {
      const response = await axiosInstanceSite.get(
        `/wirecard/subscription/${subscriptionCode}/invoices`
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
  @action
  listSubscriberOrders = async (subscriberId, params) => {
    try {
      const response = await axiosInstanceSite.get(
        `wirecard/subscriber/${subscriberId}/orders`,
        {
          params,
        }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  createSignatureAndSubscriberWirecard = async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/wirecard/subscriptions?new_customer=true",
        payload,
        { headers: { "Content-Type": "application/json" } }
      )
      this.subscriberWirecardResponse = response.data
      await new Promise((r) => setTimeout(r, 10000))
      return response.data
    } catch (error) {
      return error.response
    }
  }

  @action
  createSignatureWirecard = async (payload) => {
    try {
      const response = await axiosInstanceSite.post(
        "/wirecard/subscriptions",
        payload,
        { headers: { "Content-Type": "application/json" } }
      )
      this.subscriberWirecardResponse = response.data
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  createSubscriberAnonimatta = async (payload) => {
    try {
      const response = await axiosInstance.post("/subscriber", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  getAddressSubs = async (id) => {
    try {
      const response = await axiosInstanceSite.get(
        `/subscriber/address/by/${id}`,
        { headers: { "Content-Type": "application/json" } }
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  newSubscriberAnonimatta = async (payload) => {
    try {
      const response = await axiosInstance.post("/subscriber/new", payload, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  updateSubscriberAnonimatta = async (id, payload) => {
    try {
      const response = await axiosInstanceSite.put(
        `/subscriber/update/${id}`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  updateAddressSubscriberAnonimatta = async (id, payload) => {
    try {
      const response = await axiosInstanceSite.put(
        `/subscriber/update/address/${id}`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  activateSubscriberAnonimatta = async (id) => {
    try {
      const response = await axiosInstance.put(`/subscriber/activate/${id}`, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  searchAddressViaCep = async (addressViaCep) => {
    try {
      const response = await axiosInstanceViaCep.get(`/${addressViaCep}/json`)
      if (_get(response, "data.erro", false)) {
        return {
          error: {
            message: "Cep Inválido!",
          },
        }
      }
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

  @action
  validate = async (data) => {
    try {
      await axiosInstance.get("/subscriber/validate", {
        headers: { "Content-Type": "application/json" },
        params: { ...data },
      })

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  @action
  generatePixCode = async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/pix/createPixPayment",
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
  @action
  generateSubscribedPixCode = async (payload) => {
    try {
      const response = await axiosInstance.post(
        `/pix/createSubscribedPixPayment`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
  @action
  verifyPixPayment = async (paymentId) => {
    try {
      const response = await axiosInstance.get(
        `pix/checkPixPayment/${paymentId}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return {
        ...response.data,
      }
    } catch (error) {
      return error.response.data
    }
  }

  @action
  generateCouponDiscount = async (id) => {
    try {
      const response = await axiosInstance.get(`/wirecard/coupons/${id}`, {
        headers: { "Content-Type": "application/json" },
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  @action
  updateSubscriberPlayerId = async (id, playerId) => {
    try {
      const res = await axiosInstance.put(
        `/subscriber/update-playerid/${id}/${playerId}`,
        {},
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }
  @action
  updateSubscriberAdmin = async (id, data) => {
    try {
      const response = await axiosInstanceAdmin.put(
        `/subscriber/admin/update/${id}`,
        { ...data },
        { headers: { "Content-Type": "application/json" } }
      )
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
}
