import { axiosInstance } from "@service"
import { action } from "mobx"

export class Upload {
  @action
  getSignedUrlS3 = async (params) => {
    try {
      const response = await axiosInstance.get("fileUpload/getSignedUrlS3", {
        params,
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  @action
  uploadToS3 = async (url, file) => {
    try {
      await axiosInstance.put(url, file)
      return true
    } catch (error) {
      return false
    }
  }
}
