import { axiosInstanceSite } from "@service"
import _get from "lodash/get"
import { action } from "mobx"

export class Favorites {
  @action
  findAllPostsLikedByUser = async (id, params) => {
    try {
      const res = await axiosInstanceSite.get(
        `/post/findAllLikedByUser/${id}`,
        {
          params: {
            ...(params ?? null),
          },
        }
      )
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  findAllEssayMediasLikedByUser = async () => {
    try {
      const res = await axiosInstanceSite.get("/essay-photo-video/favorite")
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }

  @action
  findAllFollowing = async (id) => {
    try {
      const res = await axiosInstanceSite.get(`/following/${id}`)
      return _get(res, "data", [])
    } catch (error) {
      return _get(error, "response.data", null)
    }
  }
}
