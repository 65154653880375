import { Affiliated } from "@modules/Affiliated/store"
import { Auth } from "@modules/Auth/Admin/store"
import { AuthSite } from "@modules/Auth/Site/store"
import { Banners } from "@modules/Banners/store"
import { Essay } from "@modules/Essay/store"
import { Favorites } from "@modules/Favorites/store"
import { Feed } from "@modules/Feed/store"
import { Notifications } from "@modules/Notifications/store"
import { Post } from "@modules/Post/store"
import { Profile } from "@modules/Profile/store"
import {
  RegistrationCreatorAffiliated,
  RegistrationCreatorSubscriber,
} from "@modules/Registration/store"
import { Upload } from "@modules/Upload/store"
import _includes from "lodash/includes"
import _map from "lodash/map"
import { create } from "mobx-persist"
import React from "react"

const stores = {
  auth: new Auth(),
  authSite: new AuthSite(),
  registrationAffiliated: new RegistrationCreatorAffiliated(),
  registrationSubscriber: new RegistrationCreatorSubscriber(),
  banners: new Banners(),
  post: new Post(),
  feed: new Feed(),
  essay: new Essay(),
  affiliated: new Affiliated(),
  upload: new Upload(),
  profile: new Profile(),
  favorites: new Favorites(),
  notifications: new Notifications(),
}

const hydrate = create()

const blacklist = [
  "registrationAffiliated",
  "registrationSubscriber",
  "banners",
  "post",
  "feed",
  "essay",
  "affiliated",
  "upload",
  "profile",
  "favorites",
]

_map(stores, (store, name) => {
  if (_includes(blacklist, name)) {
    return
  }

  if (typeof window !== "undefined") {
    hydrate(name, store)
  }
})

export const storesContext = React.createContext(stores)
